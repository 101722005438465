import { Output } from '@angular/core';
import 'reflect-metadata';
// import 'es6-shim';
import { Type } from 'class-transformer'
import { IDull } from 'src/app/models/bitInfo.model';
import { jsonIgnore } from "json-ignore";
import { ServiceLocator } from './locator.service';
import { DullImageService } from '../services/dull-image/dull-image.service';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { AppConstants, ImageUploadStatus, RetryConst } from 'src/app/shared/constants/app.constants';
import { retry } from 'rxjs';

export class Dull extends IDull {
    //this is the string builder for the fancy bit image thumbnail
    get fancyBitThumbnailUrl() {
        return `${environment.appUrl}/BitImages/${this.partNumber}_TopView_thumb.png`;
    }

    //do things like this so its easy to apply unit identifiers, unit conversion to the data for display purposes!
    get displayBitDiameter() {
        let unitIdentifier: string = '"';
        return `${this.bitSize} ${unitIdentifier}`;
    }

    //here is another example of why we use getters!  apply regional formatting to datetime
    get displayModifiedDate() {
        //the addition of the -0000 is a hack because we are not storing the datetime correctly.
        const date = new Date(this.modifiedDate! + '-0000');

        let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return date.toLocaleString("en-US", { timeZone: zone });
    }

    public numberOfPages: number = <number>{};
}

@Output()
export class DullEventModel {
    //#region Properties
    public id: string = <string>{};
    public serialNumber: string = <string>{};
    public partNumber: string = <string>{};
    public workOrderNumber: string = <string>{};
    public createdDate: string = <string>{};
    public isDeleted: boolean = false;
    public bitSize: number = <number>{};
    public bitStyle: string = <string>{};
    public bitManufacturer: string = <string>{};
    public bhVersion: boolean = false;
    public createdUserId: number = <number>{};
    public isAMOEvent: boolean = false;
    public status: number = 1;
    public message: string = <string>{};
    public isSuccess: boolean = false;
    public isResearchBit: boolean = false;

    @Type(() => ImageCollection)
    @Output() public imageCollection: ImageCollection[] = [];

    @Type(() => ImageCollection)
    @Output() public dyePenImageCollection: ImageCollection = new ImageCollection();

    @Type(() => ImageCollection)
    @Output() public postRepairImageCollection: ImageCollection = new ImageCollection();
    //#endregion

    //#region Getters and Setters
    //this is the string builder for the fancy bit image thumbnail
    get fancyBitThumbnailUrl() {
        return `${environment.appUrl}/BitImages/${this.partNumber}_TopView_thumb.png`;
    }

    get displayBitDiameter() {
        let unitIdentifier: string = 'in';
        return `${this.bitSize} ${unitIdentifier}`;
    }
    //#endregion

    constructor() {
        this.id = 'null';
    }
}

@Output()
export class ImageCollection {

    @Type(() => DullImageModel)
    @Output() public images: DullImageModel[] = [];

    @Type(() => DullImageModel)
    @Output() public contextImages?: DullImageModel[] = [];

    @Type(() => DullImageModel)
    @Output() public overlayImages?: DullImageModel[] = [];

    public collectionType: string = <string>{};
    public collectionId: number = 0;
    public collectionTitle: string = <string>{};
    public collectionOrder: number = 0;

    get imageLength() {
        return this.images.length
    }

    //this add a new image to the existing collection
    public AddImage(dullEventId: string, type: string): DullImageModel | undefined {
        try {
            // new instance of DullImageModel type, create uuids etc for the image and insert it into the images[]
            let newImage = new DullImageModel();
            this.images.push(newImage);
            newImage.dullEventId = dullEventId;
            newImage.imageNumber = this.images.length;
            newImage.collectionId = (type == AppConstants.CollectionType[0] ? this.collectionId : 0);
            newImage.isAdditionalImage = true;
            newImage.id = Guid.create().toString();
            newImage.thumbnailId = Guid.create().toString();
            newImage.lowResImageId = Guid.create().toString();
            newImage.collectionType = (type == AppConstants.CollectionType[0] ? this.collectionType : type);
            newImage.collectionOrder = this.collectionOrder
            return newImage;
        }
        catch {
            //console.log("Some errror occured while adding a new image")
            return undefined;
        }
    }
}

@Output()
export class DullImageModel {

    @jsonIgnore()
    public highResolutionAsset: DigitalAsset;
    @jsonIgnore()
    public lowResolutionAsset: DigitalAsset;
    @jsonIgnore()
    public thumbnailAsset: DigitalAsset;

    @jsonIgnore()
    public dullImageService;

    constructor() {
        this.dullImageService = ServiceLocator.injector.get(DullImageService);

        this.highResolutionAsset = new DigitalAsset();
        this.lowResolutionAsset = new DigitalAsset();
        this.thumbnailAsset = new DigitalAsset();
    }

    public dullEventId: string = <string>{};
    public collectionId: number = <number>{};
    public imageNumber: number = <number>{};
    public id: string = '';
    public thumbnailId: string = <string>{};
    public lowResImageId: string = <string>{};
    public uploadedUser: string = <string>{};
    public uploadedDate: string = <string>{};
    public status: number = <number>{};
    @Type(() => Overlay)
    public overlay: Overlay = <Overlay>{};

    public isAdditionalImage: boolean = false;
    public collectionType: string = '';
    public collectionOrder: number = 0;
    public enableAdd: boolean = false;
    @Output() public isApproved: boolean = false;

    public result: Result[] = [];

    //used for setting the asset urls from the BE response
    set highResImageUrl(url: string) {
        if (url != '' && url != undefined) {
            this.isApproved = true;
            this.highResolutionAsset.imageSource = '';
            this.highResolutionAsset.imageSource = url;
        }
    }

    set lowResImageUrl(url: string) {
        if (url != '' && url != undefined) {
            this.lowResolutionAsset.imageSource = url;
        }
    }

    set thumbnailUrl(url: string) {
        if (url != '' && url != undefined) {
            this.thumbnailAsset.imageSource = url;
        }
    }

    get canvasId() {
        return 'previewCanvas_' + (this.imageNumber);
    }

    get previewCanvasId() {
        return "canvas_" + this.collectionId + '_' + this.imageNumber;
    }

    get captureCanvasId() {
        return 'captureCanvas_' + this.collectionId + (this.imageNumber - 1);
    }

    get mlResultsCanvasId() {
        return "canvas_results_" + this.collectionId + '_' + (this.imageNumber - 1);
    }

    get arcCanvasId() {
        return "canvas_arc_" + this.collectionId + '_' + (this.imageNumber - 1);
    }

    get tempCanvasId() {
        return "canvas_temp_" + this.collectionId + '_' + (this.imageNumber - 1);
    }

    get bboxButtonId() {
        return "bbox_" + this.collectionId + '_' + (this.imageNumber - 1);
    }

    public drawOverlayBoundingBox: boolean = false;

    get imageId() {
        return "image_" + this.collectionId + '_' + (this.imageNumber - 1);
    }

    get dullResultsImageTitle() {
        if (this.isAdditionalImage) return 'Context ' + this.imageNumber;
        else return 'Detail ' + this.imageNumber;
    }

    get classification() {
        if (this.isAdditionalImage) return "Context";

        if (!this.overlay) {
            return "Context"
        }
        else {
            return this.overlay.classification;
        }
    }

    @Output() get lowResolutionSource() {
        if (this.highResolutionAsset.blobSourceOnly != undefined) return this.highResolutionAsset.blobSourceOnly;
        if (this.lowResolutionAsset.imageSource != undefined) return this.lowResolutionAsset.imageSource;
        return '';
    }

    @Output() get thumbnailSource() {
        if (this.highResolutionAsset.blobSourceOnly != undefined) return this.highResolutionAsset.blobSourceOnly;
        if (this.thumbnailAsset.imageSource != undefined) return this.thumbnailAsset.imageSource;
        return '';
    }

    //this is specific to zoom window so we can see the high res
    @Output() get highResolutionSource() {
        if (this.highResolutionAsset.imageSource != undefined) return this.highResolutionAsset.imageSource;
        return '';
    }

    @Output() get lowResolutionSourceForCropper() {
        if (this.lowResolutionAsset.imageSource != undefined) return this.lowResolutionAsset.imageSource;
        if (this.highResolutionAsset.blobSourceOnly != undefined) return this.highResolutionAsset.blobSourceOnly;
        return '';
    }

    public cleanImage() {
        this.isApproved = false;
        this.highResolutionAsset.cleanImage();
        this.lowResolutionAsset.cleanImage();
        this.thumbnailAsset.cleanImage();
    }

    public async saveImage() {
        // let start = new Date().getTime();
        // console.log(`${this.id} : saveImage/azureUpload started: ${Date()}`);

        const reader = new FileReader();

        //event for when the reader has completed the load
        reader.addEventListener(
            "load",
            () => {
                // console.log(`${this.id} : blob stream read completed`);
                try {
                    var imageCopy = JSON.parse(JSON.stringify(reader.result));
                    var currentImage = imageCopy.replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, '');
                    var payload = {
                        dullEventId: this.dullEventId,
                        imageId: this.id,
                        thumbnailId: this.thumbnailId,
                        lowResImageId: this.lowResImageId,
                        image: currentImage,
                        imageNumber: this.imageNumber,
                        boundingBox: (this.overlay && this.overlay.boundingBoxes) ? JSON.stringify(this.overlay.boundingBoxes) : "{}",
                        collectionId: this.collectionId,
                        isAdditionalImage: this.isAdditionalImage,
                        collectionType: this.collectionType,
                        collectionOrder: this.collectionOrder
                    }
                    //console.log(`${this.id} : uploadImageToBlob`);
                    this.dullImageService.uploadImageToBlob(payload)
                        .pipe(
                            retry({ count: RetryConst.retryAttempt, delay: RetryConst.delayLimit }),
                        )
                        .subscribe((rs) => {
                            if (rs.isSuccess) {
                                if (rs.imageUrls.length == 3) {
                                    this.highResImageUrl = rs.imageUrls[0];
                                    this.thumbnailUrl = rs.imageUrls[1];
                                    this.lowResImageUrl = rs.imageUrls[2];
                                    this.status = rs.status;
                                }
                                // let totalElapsed = new Date().getTime() - start;
                                // console.log(`${this.id} : saveImage/azureUpload: ${totalElapsed} milliseconds`);
                            }
                            else {
                                this.status = rs.status;
                                //let totalElapsed = new Date().getTime() - start;
                                //console.log(`${this.id} : FAILED saveImage/azureUpload: ${totalElapsed} milliseconds`);
                            }
                        }, (error) => {
                            this.status = ImageUploadStatus.uploadFailed;
                            //console.log("error", JSON.stringify(error))
                        });
                }
                catch (error) { console.log(`${this.id} : FAILED saveImage/azureUpload: ${error}`); }
            },
            false,
        );

        if (this.highResolutionAsset.blob) {
            //convert image file to base64 string
            reader.readAsDataURL(this.highResolutionAsset.blob);
        }
    }

    public async deleteAdditionalImage() {
        var payload = {
            dullEventId: this.dullEventId,
            thumbnailId: this.thumbnailId,
            lowResImageId: this.lowResImageId,
            imageId: this.id,
            collectionType: this.collectionType,
            CollectionId: this.collectionId,
            imageNumber: this.imageNumber
        }
        this.dullImageService.deleteAdditionalImage(payload).subscribe((rs) => {
            if (rs) {
            }
        });
    }
}

@Output()
export class DigitalAsset {
    private _blob: Blob | undefined;
    private _blobURL: string = '';

    //used to take the temporary image from the camera and set it as this image
    public setBlobFromTemp() {
        this._blob = this._tempBlob;
        this._blobURL = URL.createObjectURL(<Blob>this._blob!);
        this.destoryTempBlob();
    }

    get blob(): Blob {
        return this._blob!;
    }

    //temporary blob is used to take the initial picture from the camera
    //temporary blob allows us to take a replacement image without writing over the existing image. the existing image is only written over if the user saves the image
    //and calls the setBlobFromTemp function.
    private _tempBlob: Blob | undefined;
    private _tempBlobURL: string = '';

    //we set the temporary blob with the image from the camera
    set tempBlob(blob: Blob) {
        this._tempBlob = blob;
        this._tempBlobURL = URL.createObjectURL(<Blob>this._tempBlob!);
    }

    public _imageURL: string = '';

    @Output() public get imageSource() {
        if (this._tempBlob != undefined && this._tempBlobURL != '') return this._tempBlobURL;
        if (this._blob != undefined && this._blobURL != '') return this._blobURL;
        if (this._imageURL != '') return this._imageURL;
        return undefined;
    }

    @Output() public get blobSourceOnly() {
        if (this._tempBlob != undefined && this._tempBlobURL != '') return this._tempBlobURL;
        if (this._blob != undefined && this._blobURL != '') return this._blobURL;
        return undefined;
    }

    public set imageSource(data: string | undefined) {
        if (data != undefined) {
            this._imageURL = data;
        }
    }

    public destoryBlob() {
        URL.revokeObjectURL(this._blobURL);
        this._blob = undefined;
        this._blobURL = '';
    }

    public destoryTempBlob() {
        URL.revokeObjectURL(this._tempBlobURL);
        this._tempBlob = undefined;
        this._tempBlobURL = '';
    }

    public cleanImage() {
        URL.revokeObjectURL(this._blobURL);
        this._blob = undefined;
    }
}

export class Overlay {
    @Type(() => Cutter)
    public cutters: Cutter[] = [];

    public boundingBoxes: Map<string, BoundingBox> | undefined;

    public classification: string = <string>{};
}

export class Cutter {
    @Type(() => Projection)
    projections: Projection[] = [];
}

export class Projection {
    @Type(() => Point)
    points: Point[] = [];

    @Type(() => Number)
    format: number = <number>{};
}

export class Point {
    x: number = <number>{};
    y: number = <number>{};
}

export class BoundingBox {
    centerX: number = 0;
    centerY: number = 0;
    width: number = 0;
    height: number = 0;
}

export class Result {
    id: string = <string>{};
    _cutterPNumber: string = <string>{};
    _numberNumber: number = -999.25;

    set cutterPNumber(value: string) {
        if (value != undefined) {
            this._cutterPNumber = value;
            this._numberNumber = Number.parseInt(value);
        }
    }
    get cutterPNumber() {
        return this._cutterPNumber!;
    }

    label: string = <string>{};
    cutterPredictionScore: number = 0;
    boxHeight: number = 0;
    boxWidth: number = 0;
    box_X: number = 0;
    box_Y: number = 0;
    cutterType: string = <string>{};
    primaryDullChar: string = <string>{};
    secondaryDullChar: string = <string>{};
    dullGrade?: number = 0;
    cutterUrl: string = <string>{};
    primaryDull?: string = '';
    secondaryDull?: string = '';
    primaryTableId: number = 0;
    secondaryTableId: number = 0;
    wearId: number = 0;
    comments: string = '';
    popup_Id: string = '';
    modal_Id: string = '';
    primarySubstrate?: string = <string>{};
    secondrySubstrate?: string = <string>{};
    primarySubstrateId: number = 0;
    secondrySubstrateId: number = 0;
    wear: string = '';
    wearScore: number = 0;
    primaryTableScore: number = 0;
    secondaryTableScore: number = 0;
    primarySubstrateScore: number = 0;
    secondarySubstrateScore: number = 0;
    predictionScore: number = 0;
    primaryDullSubstrate?: string = <string>{};
}